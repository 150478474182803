import React from "react";
import Helmet from "react-helmet";

const Tracking = ({ title }) => {
  var addGtag =
    typeof window !== "undefined" && window.location.hostname !== "localhost";

  return (
    <Helmet>
      {addGtag && (
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-646140-9"
        ></script>
      )}
      {addGtag && (
        <script>{`  
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        `}</script>
      )}
      {addGtag && (
        <script>{`  
          gtag("config", "UA-646140-9", {
            page_path: "${window.location.href}",
            page_title: "${title}",
          })
        `}</script>
      )}
    </Helmet>
  );
};

export default Tracking;
