import React from "react";
import { Link } from "gatsby";

import "./Navbar.scss";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link
              to="https://developer.komplett.no"
              className="navbar-item-logo"
              title="Logo"
            >
              <img
                src="https://www.komplett.no/logo/310/logo_b2c.svg"
                alt="Komplett-logo"
                style={{ width: "100%" }}
              />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-end has-text-centered">
              <Link
                className="navbar-item"
                to="https://developer.komplett.no/#who-we-are"
              >
                Hvem er vi
              </Link>
              <Link
                className="navbar-item"
                to="https://developer.komplett.no/#available-positions"
              >
                Ledige Stillinger
              </Link>
              <Link
                className="navbar-item"
                to="https://developer.komplett.no/#our-culture"
              >
                Vår Kultur
              </Link>
              <Link
                className="navbar-item"
                to="https://developer.komplett.no/#technology"
              >
                Teknologi
              </Link>
              <Link
                className="navbar-item"
                to="https://developer.komplett.no/#contact-us"
              >
                Kontakt oss
              </Link>
              <Link
                className="navbar-item"
                to="https://developer.komplett.no/blog"
              >
                Blog
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
