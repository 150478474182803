import React from "react";
import facebook from "../../img/social/facebook.svg";
import instagram from "../../img/social/instagram.svg";
import twitter from "../../img/social/twitter.svg";
import twitch from "../../img/social/twitch.svg";
import youtube from "../../img/social/youtube.svg";

import "./Footer.css";

const Footer = class extends React.Component {
  render() {
    return (
      <footer
        style={{
          maxWidth: "100vw",
          width: "100%",
        }}
        className="komplett-footer"
      >
        <section className="footer-logo">
          <div className="komplett-logo">
            <img
              src="https://www.komplett.no/logo/310/logo_b2c.svg"
              alt="Komplett-logo"
              style={{ width: "14em", height: "10em" }}
            />
          </div>
        </section>
        <div className="footer-social-icons">
          <a
            target="_blank"
            title="facebook"
            href="https://facebook.com/komplettno"
          >
            <img
              src={facebook}
              alt="Facebook"
              style={{ width: "1.5em", height: "1.5em" }}
            />
          </a>
          <a
            target="_blank"
            title="twitter"
            href="https://twitter.com/komplettno"
          >
            <img
              src={twitter}
              alt="Twitter"
              style={{ width: "1.5em", height: "1.5em" }}
            />
          </a>
          <a
            target="_blank"
            title="instagram"
            href="https://instagram.com/komplettno"
          >
            <img
              src={instagram}
              alt="Instagram"
              style={{ width: "1.5em", height: "1.5em" }}
            />
          </a>
          <a
            target="_blank"
            title="youtube"
            href="https://youtube.com/komplettno"
          >
            <img
              src={youtube}
              alt="Youtube"
              style={{ width: "1.5em", height: "1.5em" }}
            />
          </a>
          <a target="_blank" title="twitch" href="https://twitch.tv/komplettno">
            <img
              src={twitch}
              alt="Twitch"
              style={{ width: "1.5em", height: "1.5em" }}
            />
          </a>
        </div>
      </footer>
    );
  }
};

export default Footer;
