import React from "react";
import { Helmet } from "react-helmet";
import Footer from "./Footer/Footer";
import Navbar from "./Navbar/Navbar";
import "./all.scss";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";
import autostore from "../../static/img/autostoreMeta.jpg";

import Tracking from "./Tracking";

const TemplateWrapper = ({ children, location }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div>
      <Tracking title={title} location={location} />
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/komplett-box.svg`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/komplett-box.svg`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/komplett-box.svg`}
          sizes="16x16"
        />
        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/komplett-box.svg`}
          sizes="32x32"
        />

        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="https://developer.komplett.no/" />
        <meta property="og:image" content={autostore} />
      </Helmet>
      <Navbar />
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default TemplateWrapper;
